import * as yup from 'yup';

type AnotherBirthdayPerson = {
  id: string;
  name: string;
  age?: number;
};

type ParticipantsFormData = {
  birthdayPersonName: string;
  birthdayPersonAge?: number;
  anotherBirthdayPersons: AnotherBirthdayPerson[];
  birthdayAtWeekend: boolean;
  participantsNumber: number;
  tableBooking: boolean;
  tableNumber?: number;
  animator: boolean;
  support: boolean;
  theme: boolean;
  themeOption: string[];
};

export type CustomParticipantsFormData = Omit<
  ParticipantsFormData,
  'themeOption'
> & { themeOption: string };

const participantsSchema = yup.object<
  Record<keyof ParticipantsFormData, yup.AnySchema>
>({
  birthdayPersonName: yup.string().required('Imię solenizanta jest wymagane'),
  birthdayPersonAge: yup
    .number()
    .min(1, 'Wiek solenizanta nie może być mniejszy niż 1 rok')
    .max(12, 'Wiek solenizanta nie może być większy niż 12 lat')
    .required('Wiek solenizanta jest wymagany'),
  anotherBirthdayPersons: yup.array().of(
    yup.object<Record<keyof AnotherBirthdayPerson, yup.AnySchema>>({
      id: yup.string().required(),
      name: yup.string().required('Imię solenizanta jest wymagane'),
      age: yup
        .number()
        .min(1, 'Wiek solenizanta nie może być mniejszy niż 1 rok')
        .max(12, 'Wiek solenizanta nie może być większy niż 12 lat')
        .required('Wiek solenizanta jest wymagany')
    })
  ),
  birthdayAtWeekend: yup.boolean(),
  participantsNumber: yup
    .number()
    .required('To pole jest wymagane')
    .min(1, 'Minimalna liczba uczestników wynosi: 1')
    .max(20, 'Maksymalna liczba uczestników wynosi: 20'),
  tableBooking: yup.boolean().notRequired(),
  tableNumber: yup.number().when('tableBooking', {
    is: true,
    then: yup
      .number()
      .min(1, 'Liczba osób musi być większa niż 1')
      .max(8, 'Liczba osób nie może być większa niż 8')
      .required('To pole jest wymagane, jesli wybrano rezerwację stolika')
  }),
  animator: yup.boolean().notRequired(),
  support: yup.boolean().notRequired(),
  theme: yup.boolean().notRequired(),
  themeOption: yup.array().when('theme', {
    is: true,
    then: yup.array().min(1, 'Wybierz motyw').max(1).required('Wybierz motyw')
  })
});

const participantsInitialValues: ParticipantsFormData = {
  birthdayPersonName: '',
  birthdayPersonAge: undefined,
  anotherBirthdayPersons: [],
  birthdayAtWeekend: false,
  participantsNumber: 0,
  tableBooking: false,
  tableNumber: undefined,
  animator: false,
  support: false,
  theme: false,
  themeOption: []
};

const anotherBirthdayPersonInitialValues: AnotherBirthdayPerson = {
  id: '',
  name: '',
  age: undefined
};

const themeOptions = [
  {
    id: 'princess',
    label: 'Księżniczki i rycerze'
  },
  {
    id: 'pirate',
    label: 'Wyprawa pirackim statkiem'
  },
  {
    id: 'sport',
    label: 'Sportowe emocje'
  },
  {
    id: 'unicorn',
    label: 'Mój przyjaciel jednorożec'
  },
  {
    id: 'ice',
    label: 'Kraina lodu'
  },
  {
    id: 'island',
    label: 'Hawajska wyspa'
  },
  {
    id: 'crazy',
    label: 'Szalona paka'
  }
];

export {
  participantsSchema,
  participantsInitialValues,
  anotherBirthdayPersonInitialValues,
  themeOptions
};
export type { ParticipantsFormData, AnotherBirthdayPerson };
