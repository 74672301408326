import { useMemo, useCallback } from 'react';

import { format } from 'date-fns';
import { useShallow } from 'zustand/react/shallow';

import { useStore, usePricesStore } from 'src/hooks';
import {
  themeOptions,
  ParticipantsFormData,
  FoodFormData,
  timeFrames
} from 'src/schemas';

export const placeNames = {
  blue: 'Niebieska',
  pink: 'Różowa',
  green: 'Zielona',
  '': ''
};

type ParticipantsFieldsNames = keyof ParticipantsFormData;
type FoodFieldsNames = keyof FoodFormData;

const eveningTime = ['16:45', '17:00', '17:15'];

export const usePersonalFields = () => {
  const {
    firstname,
    lastname,
    email,
    phoneNumber,
    date,
    time,
    place,
    lastMinute
  } = useStore(
    useShallow(state => ({
      firstname: state.personalForm.firstname,
      lastname: state.personalForm.lastname,
      email: state.personalForm.email,
      phoneNumber: state.personalForm.phoneNumber,
      date: state.personalForm.date,
      time: state.personalForm.time,
      place: state.personalForm.place,
      lastMinute: state.personalForm.lastMinute
    }))
  );

  const timeFrame = timeFrames[time as keyof typeof timeFrames];

  return useMemo(
    () => ({
      firstname: {
        id: 'firstname',
        label: 'Imię *',
        summaryLabel: 'Imię',
        value: firstname
      },
      lastname: {
        id: 'lastname',
        label: 'Nazwisko *',
        summaryLabel: 'Nazwisko',
        value: lastname
      },
      email: {
        id: 'email',
        label: 'Adres email *',
        summaryLabel: 'Adres email',
        value: email
      },
      phoneNumber: {
        id: 'phoneNumber',
        label: 'Numer telefonu *',
        summaryLabel: 'Numer telefonu',
        value: phoneNumber
      },
      date: {
        id: 'date',
        label: 'Data rezerwacji *',
        summaryLabel: 'Data rezerwacji',
        value: date !== null ? format(date, 'dd/MM/yyyy') : ''
      },
      time: {
        id: 'time',
        label: 'Godzina rezerwacji *',
        summaryLabel: 'Godzina rezerwacji',
        value: time
      },
      timePlace: {
        id: 'timePlace',
        label: 'Salka urodzinkowa na wyłączność z zabawą na sali',
        summaryLabel: 'Salka urodzinkowa na wyłączność z zabawą na sali',
        value: eveningTime.includes(time)
          ? `${timeFrame?.[0]} - ${timeFrame?.[1]}`
          : `${time} - ${timeFrame?.[0]}`
      },
      timeFun: {
        id: 'timeFun',
        label: 'Godzina zabawy na sali',
        summaryLabel: 'Godzina zabawy na sali',
        value: eveningTime.includes(time)
          ? `${time} - ${timeFrame?.[0]}`
          : `${timeFrame?.[0]} - ${timeFrame?.[1]}`
      },
      place: {
        id: 'place',
        label: 'Wybrana salka *',
        summaryLabel: 'Wybrana salka',
        value: placeNames[place]
      },
      lastMinute: {
        id: 'lastMinute',
        label: 'Urodzinki last minute',
        summaryLabel: 'Urodzinki last minute',
        value: lastMinute
      }
    }),
    [
      date,
      email,
      firstname,
      lastMinute,
      lastname,
      phoneNumber,
      place,
      time,
      timeFrame
    ]
  );
};

export const useParticipantsFields = () => {
  const {
    birthdayPersonName,
    birthdayPersonAge,
    anotherBirthdayPersons,
    birthdayAtWeekend,
    participantsNumber,
    tableBooking,
    tableNumber,
    animator,
    support,
    theme,
    themeOption
  } = useStore(
    useShallow(state => ({
      birthdayPersonName: state.participantsForm.birthdayPersonName,
      birthdayPersonAge: state.participantsForm.birthdayPersonAge,
      anotherBirthdayPersons: state.participantsForm.anotherBirthdayPersons,
      birthdayAtWeekend: state.participantsForm.birthdayAtWeekend,
      participantsNumber: state.participantsForm.participantsNumber,
      tableBooking: state.participantsForm.tableBooking,
      tableNumber: state.participantsForm.tableNumber,
      animator: state.participantsForm.animator,
      support: state.participantsForm.support,
      theme: state.participantsForm.theme,
      themeOption: state.participantsForm.themeOption
    }))
  );
  const { prices } = usePricesStore(
    useShallow(state => ({
      prices: state.prices
    }))
  );

  const pricePerPerson = birthdayAtWeekend
    ? prices.participantWeekendPrice
    : prices.participantPrice;

  const themePrice = useMemo(() => {
    if (!theme) {
      return 0;
    }
    if (participantsNumber <= 10) {
      return prices.themePrice;
    }
    return (
      prices.themePrice + (participantsNumber - 10) * prices.themeOverPrice
    );
  }, [participantsNumber, prices.themeOverPrice, prices.themePrice, theme]);

  return useMemo(
    () => ({
      birthdayPersonName: {
        id: 'birthdayPersonName',
        label: 'Imię solenizanta/ki *',
        summaryLabel: 'Imię solenizanta/ki',
        value: birthdayPersonName,
        price: null,
        totalPrice: null
      },
      birthdayPersonAge: {
        id: 'birthdayPersonAge',
        label: 'Wiek solenizanta/ki *',
        summaryLabel: 'Wiek solenizanta/ki',
        value: Number(birthdayPersonAge),
        price: null,
        totalPrice: null
      },
      anotherBirthdayPersons: {
        id: 'anotherBirthdayPersons',
        label: 'Dodatkowy solenizant/ka',
        summaryLabel: 'Dodatkowy solenizant/ka',
        value: anotherBirthdayPersons,
        price: prices.anotherBirthdayPersonPrice,
        totalPrice:
          anotherBirthdayPersons.length * prices.anotherBirthdayPersonPrice
      },

      birthdayAtWeekend: {
        id: 'birthdayAtWeekend',
        label: 'Urodziny w sobotę, niedzielę lub święto',
        summaryLabel: 'Urodziny w sobotę, niedzielę lub święto',
        value: birthdayAtWeekend,
        price: null,
        totalPrice: null
      },
      participantsNumber: {
        id: 'participantsNumber',
        label: 'Liczba dzieci wraz z solenizantem/ solenizantami *',
        summaryLabel: 'Liczba dzieci wraz z solenizantem/ solenizantami',
        value: participantsNumber,
        price: pricePerPerson,
        totalPrice:
          participantsNumber < 8
            ? 8 * pricePerPerson
            : participantsNumber * pricePerPerson,
        extraInfo:
          'W przypadku ilości uczestników mniejszej niż 8, cena końcowa za liczbę uczestników wynosi tyle ile za 8 uczestników'
      },
      tableBooking: {
        id: 'tableBooking',
        label: 'Rezerwacja stolika w Restauracji dla opiekunów',
        summaryLabel: 'Rezerwacja stolika w Restauracji dla opiekunów',
        value: tableBooking,
        price: null,
        totalPrice: null
      },
      tableNumber: {
        id: 'tableNumber',
        label: 'Liczba opiekunów *',
        summaryLabel: 'Liczba opiekunów',
        value: Number(tableNumber ?? 0),
        price: prices.tablePrice,
        totalPrice:
          Number(tableNumber) > 8
            ? Number(tableNumber) * prices.tablePrice
            : null,
        extraInfo: `W celu rezerwacji stolika dla więcej niż 8 osób, prosimy o kontakt telefoniczny z Restauracją pod nr telefonu: 798-997-733.
Przy rezerwacji powyżej 8 osób obowiązuje dodatkowa opłata w kwocie 15zł/os. płatna na początku urodzin w naszej Restauracji.
Całość tej kwoty jest do wykorzystania w Restauracji w dniu urodzin.`
      },
      animator: {
        id: 'animator',
        label: 'Obsługa urodzinek z animacją - 1 animator',
        summaryLabel: 'Obsługa urodzinek z animacją - 1 animator',
        value: animator,
        price: prices.animatorPrice,
        totalPrice: Number(animator) * prices.animatorPrice
      },

      support: {
        id: 'support',
        label: 'Obsługa urodzinek z animacją - 2 animatorów',
        summaryLabel: 'Obsługa urodzinek z animacją - 2 animatorów',
        value: support,
        price: prices.supportPrice,
        totalPrice: Number(support) * prices.supportPrice
      },
      theme: {
        id: 'theme',
        label: 'Urodzinki z motywem',
        summaryLabel: `Urodzinki z motywem - ${
          themeOptions.find(option => option.id === themeOption[0])?.label ??
          'Brak motywu'
        }`,
        value: theme,
        price: prices.themePrice,
        totalPrice: themePrice,
        extraInfo: `Urodzinki z motywem - za każdą dodatkową osobę powyżej 10 osób doliczana jest opłata ${prices.themeOverPrice} zł/os.`
      },

      themeOption: {
        id: 'themeOption',
        label: 'Wybierz motyw urodzin',
        summaryLabel: 'Wybierz motyw urodzin',
        value:
          themeOptions.find(option => option.id === themeOption[0])?.label ??
          'Brak motywu',
        price: prices.themePrice,
        totalPrice: null
      }
    }),
    [
      animator,
      anotherBirthdayPersons,
      birthdayAtWeekend,
      birthdayPersonAge,
      birthdayPersonName,
      participantsNumber,
      pricePerPerson,
      prices.animatorPrice,
      prices.anotherBirthdayPersonPrice,
      prices.supportPrice,
      prices.tablePrice,
      prices.themeOverPrice,
      prices.themePrice,
      support,
      tableBooking,
      tableNumber,
      theme,
      themeOption,
      themePrice
    ]
  );
};

export const useFoodFields = () => {
  const {
    cake,
    ownCake,
    kidChampagne,
    pizzaMargherita,
    pizzaMargheritaHam,
    fries,
    nuggets,
    fruits,
    iceCream,
    balloon,
    balloonWithNumber,
    piniata,
    notes,
    participantsNumber
  } = useStore(
    useShallow(state => ({
      cake: state.foodForm.cake,
      ownCake: state.foodForm.ownCake,
      kidChampagne: state.foodForm.kidChampagne,
      pizzaMargherita: state.foodForm.pizzaMargherita,
      pizzaMargheritaHam: state.foodForm.pizzaMargheritaHam,
      fries: state.foodForm.fries,
      nuggets: state.foodForm.nuggets,
      fruits: state.foodForm.fruits,
      iceCream: state.foodForm.iceCream,
      balloon: state.foodForm.balloon,
      balloonWithNumber: state.foodForm.balloonWithNumber,
      piniata: state.foodForm.piniata,
      notes: state.foodForm.notes,
      participantsNumber: state.participantsForm.participantsNumber
    }))
  );
  const { prices } = usePricesStore(
    useShallow(state => ({
      prices: state.prices
    }))
  );

  const getCakePriceBySize = useCallback(
    (size: string) => {
      switch (size) {
        case 'S':
          return prices.smallCakePrice;
        case 'M':
          return prices.mediumCakePrice;
        case 'L':
          return prices.largeCakePrice;
        default:
          return 0;
      }
    },
    [prices.largeCakePrice, prices.mediumCakePrice, prices.smallCakePrice]
  );

  const getCakePrice = useCallback(
    (size: string, wafer: boolean, cakeFountain: boolean) => {
      let price = getCakePriceBySize(size);

      if (wafer) {
        price += prices.wafferPrice;
      }
      if (cakeFountain) {
        price += prices.cakeFountainPrice;
      }

      return price;
    },
    [getCakePriceBySize, prices.cakeFountainPrice, prices.wafferPrice]
  );

  const cakePrice = useMemo(() => {
    let price = 0;

    cake.forEach(({ size, wafer, cakeFountain }) => {
      const basePrice = getCakePriceBySize(size);
      price += basePrice;

      if (wafer) {
        price += prices.wafferPrice;
      }
      if (cakeFountain) {
        price += prices.cakeFountainPrice;
      }
    });

    return price;
  }, [cake, getCakePriceBySize, prices.cakeFountainPrice, prices.wafferPrice]);

  const piniataPrice = useMemo(() => {
    if (!piniata) {
      return 0;
    }
    if (participantsNumber <= 8) {
      return prices.piniataPrice;
    }
    return (
      prices.piniataPrice + (participantsNumber - 8) * prices.piniataOverPrice
    );
  }, [
    participantsNumber,
    piniata,
    prices.piniataOverPrice,
    prices.piniataPrice
  ]);

  return useMemo(
    () => ({
      cake: {
        id: 'cake',
        label: 'Wybierz tort',
        value: cake.length,
        values: cake.map(c => ({
          id: {
            id: 'id',
            label: 'id',
            summaryLabel: 'id',
            value: c.id,
            price: null
          },
          size: {
            id: 'size',
            label: 'Rozmiar tortu *',
            summaryLabel: 'Rozmiar tortu',
            value: c.size,
            price: getCakePriceBySize(c.size)
          },
          flavor: {
            id: 'flavor',
            label: 'Smak tortu *',
            summaryLabel: 'Smak tortu',
            value: c.flavor,
            price: null
          },
          cakeText: {
            id: 'cakeText',
            label: 'Napis na torcie lub opłatku (jeśli wybrano opłatek)',
            summaryLabel: 'Napis na torcie lub opłatku (jeśli wybrano opłatek)',
            value: c.cakeText,
            price: null
          },
          wafer: {
            id: 'cakeText',
            label: 'Opłatek na torcie',
            summaryLabel: 'Opłatek na torcie',
            value: c.wafer,
            price: prices.wafferPrice
          },
          waferType: {
            id: 'waferType',
            label: 'Wzór na opłatku *',
            summaryLabel: 'Wzór na opłatku ',
            value: c.waferType,
            price: null
          },
          candle: {
            id: 'candle',
            label: 'Świeczka cyfra',
            summaryLabel: 'Świeczka cyfra',
            value: c.candle,
            price: null
          },
          candleNumber: {
            id: 'candleNumber',
            label: 'Cyfra na świeczce *',
            summaryLabel: 'Cyfra na świeczce',
            value: c.candleNumber,
            price: null
          },
          cakeFountain: {
            id: 'cakeFountain',
            label: 'Fontanna tortowa',
            summaryLabel: 'Fontanna tortowa',
            value: c.cakeFountain,
            price: prices.cakeFountainPrice
          },
          totalPrice: getCakePrice(c.size, c.wafer, c.cakeFountain)
        })),
        price: null,
        totalPrice: cakePrice
      },
      freePizza: {
        id: 'freePizza',
        label: 'Pizza Margarita 32cm',
        value: Math.ceil(participantsNumber / 8),
        price: null,
        totalPrice: null
      },
      freeDrinks: {
        id: 'freeDrinks',
        label: 'Napoje 600ml (sok 300ml / woda 300ml)',
        value: participantsNumber,
        price: null,
        totalPrice: null
      },
      freeFood: {
        id: 'freeFood',
        label: 'Słodki oraz słony poczęstunek',
        value: participantsNumber,
        price: null,
        totalPrice: null
      },
      ownCake: {
        id: 'ownCake',
        label: 'Opłata za wniesienie własnego tortu lub piniaty',
        value: ownCake,
        price: prices.ownCakePrice,
        totalPrice: ownCake * prices.ownCakePrice
      },
      kidChampagne: {
        id: 'kidChampagne',
        label: 'Szampan dla dzieci',
        value: kidChampagne,
        price: prices.kidChampagnePrice,
        totalPrice: kidChampagne * prices.kidChampagnePrice
      },

      pizzaMargherita: {
        id: 'pizzaMargherita',
        label: 'Pizza Margarita 32 cm',
        value: pizzaMargherita,
        price: prices.pizzaMargheritaPrice,
        totalPrice: pizzaMargherita * prices.pizzaMargheritaPrice
      },
      pizzaMargheritaHam: {
        id: 'pizzaMargheritaHam',
        label: 'Pizza z szynką 32 cm',
        value: pizzaMargheritaHam,
        price: prices.pizzaMargheritaHamPrice,
        totalPrice: pizzaMargheritaHam * prices.pizzaMargheritaHamPrice
      },
      fries: {
        id: 'fries',
        label: 'Frytki + sos (100g)',
        value: fries,
        price: prices.friesPrice,
        totalPrice: fries * prices.friesPrice
      },
      nuggets: {
        id: 'nuggets',
        label: 'Nuggets (5 szt.)',
        value: nuggets,
        price: prices.nuggetsPrice,
        totalPrice: nuggets * prices.nuggetsPrice
      },
      fruits: {
        id: 'fruits',
        label: 'Talerz owoców',
        value: fruits,
        price: prices.fruitsPrice,
        totalPrice: fruits * prices.fruitsPrice
      },

      iceCream: {
        id: 'iceCream',
        label: 'Lód w wafelku (1 gałka)',
        value: iceCream,
        price: prices.iceCreamPrice,
        totalPrice: iceCream * prices.iceCreamPrice
      },
      balloon: {
        id: 'balloon',
        label: 'Balon foliowy 18” wypełniony helem',
        value: balloon,
        price: prices.balloonPrice,
        totalPrice: balloon * prices.balloonPrice,
        extraInfo: 'Wybór z dostępnych wzorów na miejscu.'
      } as const,
      balloonWithNumber: {
        id: 'balloonWithNumber',
        label: 'Balon Cyfra wypełniony helem',
        value: balloonWithNumber,
        price: prices.balloonWithNumberPrice,
        totalPrice: balloonWithNumber.length * prices.balloonWithNumberPrice
      } as const,
      piniata: {
        id: 'piniata',
        label: 'Zaczarowana Piniata',
        value: piniata,
        price: prices.piniataPrice,
        totalPrice: piniata.length * piniataPrice,
        extraInfo: `Zaczarowana Piniata - za każdą dodatkową osobę powyżej 8 doliczana jest opłata ${prices.piniataOverPrice} zł/os.`
      } as const,
      notes: {
        id: 'notes',
        label: 'Dodatkowe uwagi',
        value: notes,
        price: null,
        totalPrice: null
      }
    }),
    [
      cake,
      cakePrice,
      participantsNumber,
      ownCake,
      prices.ownCakePrice,
      prices.kidChampagnePrice,
      prices.pizzaMargheritaPrice,
      prices.pizzaMargheritaHamPrice,
      prices.friesPrice,
      prices.nuggetsPrice,
      prices.fruitsPrice,
      prices.iceCreamPrice,
      prices.balloonPrice,
      prices.balloonWithNumberPrice,
      prices.piniataPrice,
      prices.piniataOverPrice,
      prices.wafferPrice,
      prices.cakeFountainPrice,
      kidChampagne,
      pizzaMargherita,
      pizzaMargheritaHam,
      fries,
      nuggets,
      fruits,
      iceCream,
      balloon,
      balloonWithNumber,
      piniata,
      piniataPrice,
      notes,
      getCakePriceBySize,
      getCakePrice
    ]
  );
};

export const useTotalPrice = () => {
  const participantsFields = useParticipantsFields();
  const foodFields = useFoodFields();

  return useMemo(() => {
    const participantsPrice = (
      Object.keys(participantsFields) as ParticipantsFieldsNames[]
    ).reduce((acc, cv) => {
      return acc + Number(participantsFields[cv].totalPrice);
    }, 0);

    const foodPrice = (Object.keys(foodFields) as FoodFieldsNames[]).reduce(
      (acc, cv) => {
        return acc + Number(foodFields[cv].totalPrice);
      },
      0
    );

    return {
      totalPrice:
        participantsPrice +
        foodPrice -
        Number(participantsFields.tableNumber?.totalPrice),
      restaurantPrice: Number(participantsFields.tableNumber?.totalPrice),
      finalPrice:
        participantsPrice +
        foodPrice -
        Number(participantsFields.tableNumber?.totalPrice) -
        200
    };
  }, [foodFields, participantsFields]);
};

export const useInvoiceFields = () => {
  const {
    firstname,
    lastname,
    company,
    street,
    houseNo,
    aptNo,
    zipCode,
    city,
    nip
  } = useStore(
    useShallow(state => ({
      firstname: state.invoiceForm.firstname,
      lastname: state.invoiceForm.lastname,
      company: state.invoiceForm.company,
      street: state.invoiceForm.street,
      houseNo: state.invoiceForm.houseNo,
      aptNo: state.invoiceForm.aptNo,
      zipCode: state.invoiceForm.zipCode,
      city: state.invoiceForm.city,
      nip: state.invoiceForm.nip
    }))
  );

  return useMemo(
    () => ({
      firstname: {
        id: 'firstname',
        label: 'Imię *',
        summaryLabel: 'Imię',
        value: firstname
      },
      lastname: {
        id: 'lastname',
        label: 'Nazwisko *',
        summaryLabel: 'Nazwisko',
        value: lastname
      },
      company: {
        id: 'company',
        label: 'Nazwa firmy',
        summaryLabel: 'Nazwa firmy',
        value: company
      },
      street: {
        id: 'street',
        label: 'Ulica *',
        summaryLabel: 'Ulica',
        value: street
      },
      houseNo: {
        id: 'houseNo',
        label: 'Numer domu *',
        summaryLabel: 'Numer domu',
        value: houseNo
      },
      aptNo: {
        id: 'aptNo',
        label: 'Numer mieszkania',
        summaryLabel: 'Numer mieszkania',
        value: aptNo
      },
      zipCode: {
        id: 'zipCode',
        label: 'Kod pocztowy *',
        summaryLabel: 'Kod pocztowy',
        value: zipCode
      },
      city: {
        id: 'city',
        label: 'Miasto *',
        summaryLabel: 'Miasto',
        value: city
      },
      nip: {
        id: 'nip',
        label: 'NIP',
        summaryLabel: 'NIP',
        value: nip
      }
    }),
    [aptNo, city, company, firstname, houseNo, lastname, nip, street, zipCode]
  );
};
